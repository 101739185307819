import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [4,3];

export const dictionary = {
		"/[[lang=lang]]/(frontend)": [~11,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/dashboard": [~12,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/email-previews": [~13,[2,4],[,5]],
		"/[[lang=lang]]/(auth)/login": [6,[2,3]],
		"/[[lang=lang]]/(auth)/password-reset": [7,[2,3]],
		"/[[lang=lang]]/(auth)/password-reset/[token]": [~8,[2,3]],
		"/[[lang=lang]]/(frontend)/profile": [14,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/schedule/[[start=date]]/[[stop=date]]/[[shiftId]]": [15,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/settings": [~16,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/test": [~17,[2,4],[,5]],
		"/[[lang=lang]]/(frontend)/users/[[userId]]/[[action=action]]": [~18,[2,4],[,5]],
		"/[[lang=lang]]/(auth)/welcome": [~9,[2,3]],
		"/[[lang=lang]]/(auth)/welcome/[companyUserId]": [~10,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';